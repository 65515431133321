import React, { useEffect } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

const Testimonials = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const testimonials = [
    {
      name: "Ananya Roy",
      title: "Textile Artist, India",
      rating: 5,
      image: "https://readymadeui.com/team-2.webp",
      feedback: "The quality of the handwoven fabrics from Weaves Fabrics is unmatched. Each piece feels like a work of art. I’m proud to offer these to my clients."
    },
    {
      name: "Rajesh Kumar",
      title: "Fashion Designer, USA",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgyY1GNPvwOG6gLB1STlbWivP1NZdw6wKn3VBXPOWEDH05_Pl-Yv4AhmA-g-aE7eu7aGk&usqp=CAU",
      rating: 5,
      feedback: "The variety of scarves, stoles, and shawls offered by Weaves Fabrics perfectly complements my design collections. I trust their craftsmanship completely."
    },
    {
      name: "Priya Sharma",
      title: "Entrepreneur, India",
      image: "https://readymadeui.com/team-4.webp",
      rating: 4,
      feedback: "The fabrics are beautiful and durable. I’ve used them for both personal and professional projects, and I’m always satisfied with the result."
    },
    {
      name: "Liam Anderson",
      title: "Owner, Fabric World Boutique, UK",
      rating: 5,
      image: "https://img.freepik.com/free-photo/worldface-pakistani-guy-white-background_53876-14466.jpg?semt=ais_hybrid",
      feedback: "I’ve sourced fabrics from Weaves Fabrics for several seasons. The handwoven textures add an elegance to every piece we offer our customers."
    },
    {
      name: "Maya Patel",
      title: "Interior Designer, UAE",
      rating: 5,
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXgduCkLSAJft3PbtD43uSQaR7F4HeKWPSZg&s",
      feedback: "The quality and texture of the handwoven fabrics have added an exceptional touch to my interior designs. Each fabric is timeless and luxurious."
    },
    {
      name: "Sahil Mehta",
      title: "Tailor, India",
      rating: 4,
      image: "https://readymadeui.com/team-1.webp",
      feedback: "I’ve worked with many fabric suppliers, but the uniqueness and rich textures from Weaves Fabrics are something special. My clients always appreciate the fine details."
    },
    // {
    //   name: "Nina Taylor",
    //   title: "Sustainable Fashion Advocate, Canada",
    //   rating: 5,
    //   image: "https://readymadeui.com/team-1.webp",
    //   feedback: "Weaves Fabrics offers not just beauty but a story behind each fabric. Their commitment to quality and tradition makes them a standout in the textile industry."
    // },
    // {
    //   name: "Aiden Williams",
    //   title: "Textile Enthusiast, USA",
    //   rating: 4,
    //   image: "https://readymadeui.com/team-1.webp",
    //   feedback: "The colors and intricate patterns of the fabrics I purchased are stunning. I enjoy learning about the craftsmanship that goes into every product."
    // },
    // {
    //   name: "Isha Desai",
    //   title: "Fashion Blogger, India",
    //   rating: 5,
    //   image: "https://readymadeui.com/team-1.webp",
    //   feedback: "I love promoting Weaves Fabrics for its authentic, handwoven products. They blend tradition with elegance in a way that makes every item a standout piece."
    // },
    // {
    //   name: "Ethan Davis",
    //   title: "Craftsman, Australia",
    //   rating: 5,
    //   image: "https://readymadeui.com/team-1.webp",
    //   feedback: "The craftsmanship at Weaves Fabrics is second to none. Their textiles reflect true artistry, and every piece is made with love and care."
    // },
    // {
    //   name: "Sophie Miller",
    //   title: "Event Planner, UK",
    //   rating: 4,
    //   image: "https://readymadeui.com/team-1.webp",
    //   feedback: "I’ve used Weaves Fabrics for event decorations, and their custom fabric designs always add a personal touch to every celebration."
    // },
    // {
    //   name: "Ravi Kumar",
    //   title: "Weaving Artisan, India",
    //   rating: 5,
    //   image: "https://readymadeui.com/team-1.webp",
    //   feedback: "The passion for craftsmanship at Weaves Fabrics is evident in every piece. It’s inspiring to see such dedication to traditional weaving."
    // },
    // {
    //   name: "Clara White",
    //   title: "Luxury Fashion Retailer, Italy",
    //   rating: 5,
    //   image: "https://readymadeui.com/team-1.webp",
    //   feedback: "Weaves Fabrics’ commitment to sustainable and high-quality handwoven fabrics aligns perfectly with my brand’s values. Their products always exceed expectations."
    // },
    // {
    //   name: "Shyam Sundar",
    //   title: "Wholesale Supplier, India",
    //   rating: 5,
    //   image: "https://readymadeui.com/team-1.webp",
    //   feedback: "I have been purchasing fabrics from Weaves Fabrics for years, and their consistency in quality and service has made them my go-to supplier."
    // }
  ];


  return (
    <>
      <Navbar />
      <div className="mt-16 p-4 font-[sans-serif] bg-white max-w-6xl max-lg:max-w-3xl max-md:max-w-md mx-auto">
        <div className="md:mb-12 mb-8 text-center">
          <h2 className="text-gray-800 text-2xl font-bold">What our happy client say</h2>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="p-6 mx-auto bg-gray-100 relative rounded-lg shadow-sm"
            >
              <div className="flex flex-wrap items-center gap-4">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-14 h-14 rounded-full border-2 border-blue-600"
                />
                <div>
                  <h4 className="text-gray-800 text-sm whitespace-nowrap font-semibold">
                    {testimonial.name}
                  </h4>
                  <p className="mt-0.5 text-xs text-gray-600">{testimonial.title}</p>
                </div>
              </div>

              <div className="flex space-x-1 mt-4">
                {[...Array(5)].map((_, starIndex) => (
                  <svg
                    key={starIndex}
                    className={`w-3.5 h-3.5 ${starIndex < testimonial.rating
                      ? "fill-blue-600"
                      : "fill-[#CED5D8]"
                      }`}
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                  </svg>
                ))}
              </div>

              <div className="mt-6">
                <p className="text-gray-800 text-sm leading-relaxed">
                  {testimonial.feedback}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Testimonials;
