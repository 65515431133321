import React, { useContext } from 'react'


//import css
import './product.css'

//importing animation
import { useAutoAnimate } from '@formkit/auto-animate/react'

//importing usefetch
import useFetch from '../../hooks/useFetch'

import { useNavigate } from 'react-router-dom'

//importing motion for animation
import { motion } from 'framer-motion'

import { AuthContext } from '../../context/AuthContext'
import axios from 'axios'
import { CartContext } from '../../context/CartContext'
import { TransContext } from '../../context/TransContext'
import { Link } from 'react-router-dom'
import { useCurrency } from '../../context/Currency'

export default function Products() {
  //for animation
  const navigate = useNavigate()
  const transition = {
    duration: 1,
    delay: 0.1,
    ease: [0, 0.71, 0.2, 1.01]
  }

  const baseURL = process.env.REACT_APP_API_BASE_URL



  const { data, loading } = useFetch('/product/top')
  const { symbol } = useCurrency()

  const [parent] = useAutoAnimate()

  const { user } = useContext(AuthContext)

  const { dispatch } = useContext(CartContext)
  const TransObj = useContext(TransContext)
  const transDispatch = TransObj.dispatch
  const { totalPrice, totalSaving } = TransObj.priceDetails



  const changeCartItemRequest = async (cartItems, sellingPrice, discountedMoney) => {
    const userId = user._id
    try {
      await axios.post(`${baseURL}/user/cart/${userId}`, cartItems)
      dispatch({ type: "CART_CHANGE", payload: cartItems })
      transDispatch({ type: "TRANS_PRICE", payload: { totalPrice: totalPrice + sellingPrice, totalSaving: totalSaving + discountedMoney } })
      navigate('/cart')
    } catch (err) {
      console.log("error occured")
      dispatch({ type: "CART_FAILURE", payload: err.response.data })
    }
  }

  const handleCart = (productId, sellingPrice, discountedMoney, productSize) => {
    const cartObject = {
      productId,
      qnt: 1,
      size: productSize
    }
    let cartItems = JSON.parse(localStorage.getItem("cart")) || null

    if (cartItems === null) {
      cartItems = []
      cartItems.push(cartObject)
    } else {
      let isProductAvailable = false
      cartItems.forEach(element => {
        if (element.productId === productId) {
          element.qnt = element.qnt + 1
          isProductAvailable = true
        }
      });

      if (isProductAvailable === false) {
        cartItems.push(cartObject)
      }
    }

    if (user !== null) {
      changeCartItemRequest(cartItems, sellingPrice, discountedMoney)
    } else {
      dispatch({ type: "CART_CHANGE", payload: cartItems })
      transDispatch({ type: "TRANS_PRICE", payload: { totalPrice: totalPrice + sellingPrice, totalSaving: totalSaving + discountedMoney } })
      navigate('/cart')
    }

  }

  // const productData = [
  //   {
  //     "_id": "unique_id_1",
  //     "title": "Thread Weave Cotton Fabric",
  //     "type": "cotton",
  //     "size": "350gm",
  //     "category": "fabric",
  //     "price": 299,
  //     "discount": 10,
  //     "images": [
  //       "https://img.freepik.com/free-photo/multi-color-fabric-texture-samples_1373-434.jpg",
  //     ],
  //     "desc": "Our Thread Weave Cotton Fabric is perfect for any occasion. Made from the highest quality cotton, this 350gm pack offers superior comfort and durability.",
  //     "rating": 4.5,
  //     "createdAt": "2024-07-01T10:00:00.000Z",
  //     "updatedAt": "2024-07-01T10:00:00.000Z",
  //     "__v": 0
  //   },
  //   {
  //     "_id": "unique_id_2",
  //     "title": "Thread Weave Silk Fabric",
  //     "type": "silk",
  //     "size": "850gm",
  //     "category": "fabric",
  //     "price": 599,
  //     "discount": 5,
  //     "images": [
  //       "https://img.freepik.com/free-photo/detail-color-fabric-texture-samples_1373-99.jpg?t=st=1729155608~exp=1729159208~hmac=6a14d37950cff0aeda7049c572e83a7f00159a50cdf3ccc2f7fb47c91750551a&w=740",
  //     ],
  //     "desc": "Luxurious Thread Weave Silk Fabric in 850gm for those special projects requiring elegance and class. Soft, smooth, and durable.",
  //     "rating": 4.7,
  //     "createdAt": "2024-07-02T10:00:00.000Z",
  //     "updatedAt": "2024-07-02T10:00:00.000Z",
  //     "__v": 0
  //   },
  //   {
  //     "_id": "unique_id_3",
  //     "title": "Thread Weave Linen Fabric",
  //     "type": "linen",
  //     "size": "350gm",
  //     "category": "fabric",
  //     "price": 249,
  //     "discount": 0,
  //     "images": [
  //       "https://img.freepik.com/premium-photo/branch-with-cotton-flowers-fabric-view-from-space-text_495423-54082.jpg",
  //     ],
  //     "desc": "Lightweight and breathable Thread Weave Linen Fabric in 350gm. Perfect for those summer projects or casual wear.",
  //     "rating": 4.3,
  //     "createdAt": "2024-07-03T10:00:00.000Z",
  //     "updatedAt": "2024-07-03T10:00:00.000Z",
  //     "__v": 0
  //   },
  //   {
  //     "_id": "unique_id_4",
  //     "title": "Thread Weave Cotton Fabric",
  //     "type": "cotton",
  //     "size": "1250gm",
  //     "category": "fabric",
  //     "price": 799,
  //     "discount": 15,
  //     "images": [
  //       "https://img.freepik.com/free-photo/traditional-checkered-fabric-texture-brown-drape-textile_23-2147921860.jpg",
  //     ],
  //     "desc": "Our premium Thread Weave Cotton Fabric in a generous 1250gm pack for larger projects. Comfortable, soft, and built to last.",
  //     "rating": 4.8,
  //     "createdAt": "2024-07-04T10:00:00.000Z",
  //     "updatedAt": "2024-07-04T10:00:00.000Z",
  //     "__v": 0
  //   },
  // ]

  return (
    <div className='products'>
      <div className='productTitle'>
        <span className='topheading'>Top Products</span>
        {/* <hr className='topline'></hr> */}
      </div>
      <div className='productContainer' ref={parent}>

        {
          (loading === true) ? (
            <span>Loading please wait....</span>
          ) : (

            // data.map((product, i) => {
            data.map((product, i) => {
              //calculation if discount available
              let isDiscountAvailable = false
              let orgPrice = product.price;
              let sellingPrice;
              let savingMoney = 0;
              if (product.discount !== 0) {
                isDiscountAvailable = true
                savingMoney = Math.floor((product.discount * orgPrice) / 100)
                sellingPrice = orgPrice - savingMoney
              } else {
                sellingPrice = orgPrice
              }


              //calculation for split title
              let productTitle = product.title
              if (productTitle.length > 32) {
                productTitle = productTitle.slice(0, 32)
                productTitle += "..."
              }


              return (

                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={transition}
                  key={i}
                  className='productItem cursor-pointer'

                >{
                    isDiscountAvailable && <span className='discountSec'>{product.discount}% off</span>
                  }

                  {/* <Link to={`product/${product._id}`} style={{ textDecoration: 'none' }}> */}
                  {/* <div> */}
                  <div className='productimage' onClick={() => navigate(`/productPage`, { state: { product } })}>
                    <img src={product.images[0]} alt='' className='productImg'></img>
                  </div>
                  {/* </Link> */}
                  <div className='productContent'>
                    <div onClick={() => navigate(`/productPage`, { state: { product } })}>
                      <h3 className='productTitle'>{product.title}</h3>
                      <span className='productPrice'>
                        <strong>{symbol} {product.price}</strong>
                        {/* {isDiscountAvailable && <span className='orgPrice'> {symbol} {orgPrice}.00</span>} */}
                      </span>
                    </div>
                    {/* <p className='productDescription'>{(product.desc).slice(0, 50) + "..."}</p> */}
                    {/* <div className='productSize'><i>Pack of </i>{product.size}</div> */}
                    {/* </div> */}
                    <button className='productBtn' onClick={() => handleCart(product._id, product.price, savingMoney, product.size)}>
                      Add to Cart
                    </button>
                  </div>
                </motion.div>
              )

            })
          )
        }

      </div>
      <Link to='/store'><button className='productEndBtn'>Explore Store</button></Link>
    </div>
  )
}
